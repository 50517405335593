@import '../../styles/index';

.carousel-inner {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.carousel-button-wrapper {
  display: grid;
  place-items: center;
  width: 2rem;
}

.carousel-button {
  all: unset;
  border: 2px solid transparent;
  border-radius: 50%;
  color: $surrogate-9;
  height: 1.5rem;
  outline: none;
  width: 1.5rem;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    color: $surrogate-4;
  }
}

.carousel-button[aria-hidden] {
  outline: none;
}

.carousel-items {
  display: grid;
  grid: 1fr / auto-flow calc(100% / 1);
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;
  touch-action: pan-x;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &[data-items-per-page='2'] {
    grid: 1fr / auto-flow calc(100% / 2);
  }

  &[data-items-per-page='3'] {
    grid: 1fr / auto-flow calc(100% / 3);
  }

  &[data-items-per-page='4'] {
    grid: 1fr / auto-flow calc(100% / 4);
  }

  &[data-items-per-page='5'] {
    grid: 1fr / auto-flow calc(100% / 5);
  }
}

.carousel-item {
  height: 100%;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;
}

.indicators-wrapper {
  display: grid;
  height: 2rem;
  place-items: center;
}

.indicators {
  display: flex;
}

.indicator {
  all: unset;
  background: $surrogate-4;
  border-radius: 50%;
  cursor: pointer;
  height: 1rem;
  width: 1rem;

  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.indicator[aria-selected='true'] {
  background: $surrogate-9;
}
