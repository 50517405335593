@import '../../styles/index';

.wrapper {
  position: relative;
}

.image {
  position: relative;

  &.with-lottie {
    height: 100%;
    object-fit: contain;
    position: absolute;
    width: 100%;
  }
}

.stagebox {
  position: relative;

  @include media-from(xs) {
    margin-top: -5rem;
  }

  @include media-from(sm) {
    margin-top: -12rem;
    z-index: 3;
  }

  & section {
    border-right: transparent;
  }
}
