@import '../../styles/index';

.base {
  display: flex;
  gap: 0.5rem;
}

.arrow {
  align-items: end;
}

.arrow > * {
  margin-bottom: calc(
    6.625rem / 2 - 24px / 2
  ); // half of quote avatar height minus half button height
}

@include media-from(sm) {
  .items {
    min-height: 220px;
  }

  .arrow {
    align-items: center;
  }

  .arrow > * {
    margin-bottom: unset;
  }
}

.item {
  padding: 0 1rem;
  text-align: center;
  @include media-from(sm) {
    text-align: left;
  }
}

.quote {
  row-gap: 1.5rem;
  @include media-from(sm) {
    row-gap: 1rem;
  }
}

.carousel-indicators {
  margin-top: 1rem;
  @include media-from(sm) {
    margin-top: 0;
  }
}

.avatar-wrapper {
  grid-column: 1 / 3;

  @include media-from(sm) {
    grid-column: 1;
  }
}

.text {
  font-size: $font-size-18;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.avatar {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  width: 8rem;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;

  @include media-from(sm) {
    width: 11rem;
    height: 11rem;
    margin-left: 0;
  }
}

.cite {
  grid-column: 1 / 3;
  grid-row: 3;

  @include media-from(sm) {
    grid-row: 2;
    grid-column: 2 / 3;
  }

  strong {
    font-size: $font-size-18;

    @include media-from(sm) {
      font-size: $font-size-20;
    }
  }

  span {
    font-size: $font-size-16;

    @include media-from(sm) {
      font-size: $font-size-18;
    }
  }
}

.carousel-button-left,
.carousel-button-right {
  @include media-from(sm) {
    height: 11rem;
  }
}

.carousel-button-left {
  padding-left: 0.75rem;
}

.carousel-button-right {
  padding-right: 0.75rem;
}
